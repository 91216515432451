<template>
  <div class="container">
  <br>
  <p>Admin Page (logged in as: {{currentUser.username}})</p>
  <p>Welcome {{ currentUser.firstname }} {{ currentUser.lastname }}</p>
  <div class="btn btn-primary" @click="$router.push('/profile')">
		<slot>User Profile</slot>
	</div>
  <br><br>
  </div>
</template>
<script>
export default {
  name: "MembersPage",
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  data() {
    return {
      content: "",
    };
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
    if (this.currentUser && this.currentUser['roles']) {
      if (!this.currentUser['roles'].includes('ROLE_ADMIN')) {
        this.$router.push('/members');
      }
    }
  },
};
</script>

<style scoped>


</style>

