<template>
  <div class="container-fluid banner">
    <img src="../assets/images/skinnies.jpg" alt="skinnies">
  </div>
<section id="members">

  <div class="container">
    <div class="members-header">
      <h4><span>President's Message:</span></h4>

      <p>Hello to all fellow cyclists.</p>

      <img src="../assets/documents/chair-message.gif" class="msg" alt="chairman's message">

      <div class="container">
        <div class="row">
        <div class="col-md-6" style="margin-top: 50px;">
          <a href="https://www.murraylandscc.org.au/assets/documents/chair-message.pdf" target="new"><button class="btn btn-info"> Download as PDF </button></a>
        </div>
        <div class="col-md-6" style="margin-top: 50px;">
          <a href="https://www.murraylandscc.org.au/assets/documents/chair-message-old.pdf" target="new"><button class="btn btn-info"> Previous Chairman's Message as PDF </button></a>
        </div>
      </div>
      </div>

    </div>

    <div class="row">
    </div> <!-- // row -->
  </div> <!-- // container -->

</section> <!-- // members -->
</template>
<script>
import UserService from "../services/user.service";
export default {
  name: "AboutUs",
  data() {
    return {
      content: "",
    };
  },
  mounted() {
    UserService.getPublicContent().then(
      (response) => {
        this.content = response.data;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
};
</script>

<style scoped>

.banner {
  width: 100%;
}
.banner img {
  width: 100%;
}
.members-header {
	padding-top: 20px;
	padding-bottom: 20px;
}
.members-header h4 {
	color: #2d96ff;
	font-size: 1.5rem;
	font-weight: bold;
	font-style: italic;
	margin-bottom: 0px;
	padding-bottom: 10px;
}
.members-header p {
  padding-top: 5px;
  padding-bottom: 5px;
  color: #777;
  line-height: 150%;
  letter-spacing: 2px;
}
.members-header p span {
  padding: 0;
  margin: 0;
	font-weight: bold;
	font-style: italic;
	color: #000;
}
.members span.person {
	margin-left: 30px;
	font-weight: 100;
	color: #333;
}
.members img {
	width: 80%;
	padding: 30px;
	padding-left: 0px;
}
.members-left, .members-right {
	padding-bottom: 30px;
}

.members-header {
  text-align: center;
  pagging: 35px;
}
.members-header img.msg {
  width: 100%;
}

</style>

