<template>
<div class="container-fluid banner">
  <img src="../assets/images/kit/kit-blackchrome.jpg" alt="peleton" style="width:100%;">
</div>
<section id="contact">
  <div class="container">
    <div class="kit-header">
      <h2>Ordering Club Kit</h2>
    </div>

	<div class="row">
    <div class="col-md-5 kit-left">
      <img src="../assets/images/kit/kit-single.jpg" alt="group in club kit">
    </div>
    <div class="col-md-7 kit-right">
      <p>The Club Kit is only available to Members of the Murraylands Cycling Club, to join go to our Membership Page
        (Memberships start from $25).</p>

      <!--
      <p>The Online Store is currently closed and won't be open again until demand dictates, you can still place orders
        but at a slightly higher price. The prices are $10 per item more than the list price on the Online Store.</p>
      -->

      <p>If you would like to place an order, click on the Online Store link below:</p>
      <p><a href="https://blackchrome.com.au/shop/murraylands-cycling-club/" target="new">https://blackchrome.com.au/shop/murraylands-cycling-club/</a></p>
      <p>Click on the article you want to order, write down the quantity, article description and sizes you want
        (ensure you measure yourself up &amp; use the sizing chart) and email the details to treasurer@murraylandscc.org.au
        The order will be placed and the invoice passed on to you. See order example below:</p>

        <ul>
          <li>1 x Performance Fit Jersey - Mens - L</li>
          <li>1 x Performance Fit Bib Knicks – Mens - L</li>
          <li>1 x Performance Fit Gilet - Mens - XL</li>
        </ul>
    </div>
  </div> <!-- end row -->
  <img src="../assets/images/kit/group-kit.jpg" alt="peleton" style="width:100%;margin-bottom:30px;">
	<div class="row">
    <div class="col-md-12 kit-content">

        <p>Once orders are placed and payment made, allow 4 - 6 weeks for production and delivery. We will notify
          you when they are ready.</p>

        <p>Note: Children’s sizes can be ordered by arrangement.</p>
    </div>
  </div> <!-- end row -->
</div>
</section>
</template>
<script>
import UserService from "../services/user.service";
export default {
  name: "KitOrders",
  data() {
    return {
      content: "",
    };
  },
  mounted() {
    UserService.getPublicContent().then(
      (response) => {
        this.content = response.data;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
};
</script>

<style scoped>


.kit-header {
	padding-top: 30px;
}
.kit-header h2 {
  font-size: 1.8rem;
	color: #2d96ff;
	font-size: 1.5rem;
	font-weight: bold;
	font-style: italic;
	margin-bottom: 0px;
	padding-bottom: 10px;
}
.kit-left  {
	padding-top: 30px;
}
.kit-left img {
	width: 100%;
	padding: 30px;
	padding-left: 0px;
}
.kit-right {
	padding-top: 40px;
}
.kit-right p {
  color: #777;
  font-size: 1.1rem;
  letter-spacing: 2px;
  line-height: 120%;
  padding: 15px;
  padding-bottom: 0px;
}
.kit-content p {
  color: #777;
  font-size: 1.1rem;
  letter-spacing: 2px;
  line-height: 120%;
  padding: 15px;
  padding-top: 0px;
}
.kit-content ul {
  list-style-type: none;
}
.kit-right ul li {
  color: #777;
  font-size: 1.1rem;
  letter-spacing: 2px;
  line-height: 120%;
  padding: 15px;
  padding-top: 0px;
}
.kit-content h4 {
	color: #2d96ff;
	font-size: 1.5rem;
	font-weight: bold;
	font-style: italic;
	margin-bottom: 0px;
	padding-bottom: 10px;
}
.kit-left span {
	font-weight: bold;
	color: #000;
}
.kit-left span.person {
	margin-left: 30px;
	font-weight: 100;
	color: #333;
}
.kit-right {
	padding-bottom: 30px;
}

</style>

