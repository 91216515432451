import axios from 'axios';
import authHeader from './auth-header';
//const API_URL = 'http://localhost:8080/api/test/';
const API_URL = 'https://mlccnode.webineering.com.au/api/test/';
class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }
  getMemberBoard() {
    return axios.get(API_URL + 'member', { headers: authHeader() });
  }
  getCommitteeBoard() {
    return axios.get(API_URL + 'committee', { headers: authHeader() });
  }
  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }
}
export default new UserService();

