<template>
  <div class="container">
    <br>
    <h4>Members Page</h4>
    <p>Welcome {{ currentUser.firstname }} {{ currentUser.lastname }}
      (logged in as member: #{{currentUser.username}})</p>
    <div class="row">
      <div class="col-md-4">
        <div class="btn btn-primary" @click="$router.push('/profile')">
          <slot>User Profile</slot>
        </div>
      </div>
      <div class="col-md-4">
        <div v-if="isCommittee">
          <div class="btn btn-primary" @click="$router.push('/committee')">
            <slot>Committee Page</slot>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div v-if="isAdmin">
          <div class="btn btn-primary" @click="$router.push('/admin')">
            <slot>Admin Page</slot>
          </div>
        </div>
      </div>
    </div> <!-- end of row -->
    <br><br>
  </div>
</template>
<script>
export default {
  name: "MembersPage",
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    isCommittee() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_COMMITTEE');
      }
      return false;
    },
    isAdmin() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_ADMIN');
      }
      return false;
    }
  },
  data() {
    return {
      content: "",
    };
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
  },
};
</script>

<style scoped>


</style>

