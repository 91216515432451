<template>
  <div class="container-fluid banner">
    <img src="../assets/images/sunrise2.jpg" alt="sunrise">
  </div>
<section id="contact">
  <div class="container contact-div">
    <div class="contact-header">
      <h2>Contact Us</h2>
    </div>

	<div class="row">
    <div class="col-md-4 contact-left">
				<h4>Committee</h4>
        <p>
          <span>President:</span><br><span class="person">Phillip Rosewall</span><br>
          <span>Vice President:</span><br><span class="person">Ian Hunt</span> <br>
          <span>Secretary:</span><br><span class="person">Michelle McNeil</span><br>
          <span>Treasurer:</span><br><span class="person">Vacant</span><br>
          <span>Public Officer:</span><br><span class="person">Doug Issell</span></p>
        </div>
        <div class="col-md-4 contact-left">
          <h4>Committee Members</h4>
          <p>
            <span class="person">Kylie Hunt</span><br>
            <span class="person">Wayne Richardson</span><br>
          </p>

          <h4>Postal address:</h4>
          <p><span class="person">PO Box 186</span><br>
            <span class="person">Murray Bridge SA 5253</span></p>
      </div>

      <!-- contact form -->
      <div class="col-md-4 contact-right">

        <h3>Get in Touch</h3>
   <div>
      <transition name="fade" mode="out-in">
          <div v-if="sent" class="sent">
              <p>Thank you for your enquiry</p><p>We'll be in touch shortly!</p>
          </div>
      </transition>
   </div>
   <div v-if="!sent" class="container formGroup">
      <div class="row">
       <form @submit="onSubmit">
          <div class="col-md-12">
            <input id="input-name" v-model="form.name" type="text" required placeholder=" Your Name"/>
          </div>
          <div class="col-md-12">
            <input id="input-phone" v-model="form.phone" type="text" required placeholder=" Phone"/>
          </div>
          <div class="col-md-12">
            <input id="input-email" v-model="form.email" type="text" required placeholder=" Email Address"/>
          </div>
          <div class="col-md-12">
            <textarea id="input-comments" v-model="form.comments" type="text" required placeholder=" Comments / Enquiry"/>
          </div>
          <div class="col-md-12">
            <button type="submit" class="btn btn-primary"> Contact </button>
          </div>
       </form>
      </div> <!-- row -->
   </div>

      </div>
      <!-- // contact form -->
    </div>
  </div>
</section>
</template>


<script>
import axios from 'axios';
const querystring = require("querystring");

export default {
    data() {
        return {
          sent: false,
          form: {
              name: "",
              phone: "",
              email: "",
              comments: ""
          }
        };
    },
    methods: {
      onSubmit(e) {
          e.preventDefault();
          axios
             .post(
                 "https://www.murraylandscc.org.au/php/mail.php",
                  querystring.stringify(this.form)
             )
             .then(res => {
                 console.log(res);
                 this.sent = true;
             });
      }
    }
};
</script>


<style>

.banner {
  margin: 0;
  padding: 0;
}
.banner img {
  width: 100%;
}
.contact-header {
	padding-top: 20px;
}
.contact-div {
  padding-left: 20px;
}
.contact-left {
	padding-top: 20px;
}
.contact-left h4 {
	color: #2d96ff;
	font-size: 1.5rem;
	font-weight: bold;
	font-style: italic;
	margin-bottom: 0px;
	padding-bottom: 10px;
}
.contact-left span {
	font-weight: bold;
	color: #000;
}
.contact-left span.person {
	margin-left: 30px;
	font-weight: 100;
	color: #333;
}
.contact-right {
	padding-bottom: 30px;
}
input, textarea {
  margin-bottom: 20px;
  width: 100%;
}
.sent {
  color: red;
  font-weight: bold;
  margin-top: 50px;
}

</style>

