import { createWebHistory, createRouter } from "vue-router";
import Home from "./components/Home.vue";
import About from "./components/AboutUs.vue";
import Membership from "./components/Membership.vue";
import KitOrders from "./components/KitOrders.vue";
import Contact from "./components/ContactUs.vue";
import Members from "./components/Members.vue";
import Committee from "./components/Committee.vue";
import Admin from "./components/Admin.vue";
import Login from "./components/Login.vue";
import Register from "./components/Register.vue";
// lazy-loaded
const Profile = () => import("./components/Profile.vue")
const BoardAdmin = () => import("./components/BoardAdmin.vue")
const BoardOwner = () => import("./components/BoardOwner.vue")
const BoardUser = () => import("./components/BoardUser.vue")
const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/about",
    component: About,
  },
  {
    path: "/membership",
    component: Membership,
  },
  {
    path: "/kit",
    component: KitOrders,
  },
  {
    path: "/contact",
    component: Contact,
  },
  {
    path: "/members",
    component: Members,
  },
  {
    path: "/committee",
    component: Committee,
  },
  {
    path: "/admin",
    component: Admin,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/profile",
    name: "profile",
    // lazy-loaded
    component: Profile,
  },
  {
    path: "/admin",
    name: "admin",
    // lazy-loaded
    component: BoardAdmin,
  },
  {
    path: "/owner",
    name: "moderator",
    // lazy-loaded
    component: BoardOwner,
  },
  {
    path: "/user",
    name: "user",
    // lazy-loaded
    component: BoardUser,
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active"
});
export default router;

router.beforeEach((to, from, next) => {
  //const publicPages = ['/login', '/register', '/home', '/about', '/membership', '/kit', '/contact'];
  const authPages = ['/members', '/profile', '/admin', '/committee'];
  //const authRequired = !publicPages.includes(to.path);
  const authRequired = authPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

