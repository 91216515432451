<template>
<div class="container-fluid banner">
  <img src="../assets/images/peleton.jpg" alt="peleton" />
</div>
<div class="container">
	<h4>Join the Murraylands Cycling Club</h4>
	<p>Becoming a member of the Murraylands Cycling Club (MCC) is easy thanks to AusCycling - the governing body of all disciplines of cycling in Australia.</p>
	<p>Memberships last for 12 months from the date of purchase, and monthly payment options are available (with a slight increase in cost for
      administration expenses). Please note: Prices below do not include a $25 club fee to support club activities.</p>
	<p>Know what you want? Go straight to the AusCycling Membership page here:
      <a href="https://auscycling.org.au/membership" target="new">https://auscycling.org.au/membership</a></p>

	<h4>Memberships available are:</h4>
</div>

<div class="container">
	<div class="row">
		<div class="col-sm-5 memleft">
			<img src="../assets/images/jason.jpg" alt="peleton">
		</div>
		<div class="col-sm-7 memright">
			<span><a href="https://auscycling.org.au/membership/race-all-discipline" target="new">Race: All Disciplines:</a></span>
			<p>Any bike any race! Designed for members who want it all on two wheels, a licence where you can compete in BMX / CX / Freestyle / MTB / Road and Track right around Australia.</p>

				<table class="table table-striped memtable">
					<tr>
						<td>Adult (Ages 19 - 64)</td>
						<td class="price">$360</td>
					</tr>
					<tr>
						<td>Concession (U19, 65+, Para)</td>
						<td class="price">$175</td>
					</tr>
					<tr>
						<td>Under 13 (Ages 8 - 12)</td>
						<td class="price">$109</td>
					</tr>
					<tr>
						<td>7 and under (Ages 2 - 7)</td>
						<td class="price">$81</td>
					</tr>
				</table>

				<span><a href="https://auscycling.org.au/membership/race-off-road" target="new">Race: Off-Road:</a> </span>
				<p>Riders can participate in BMX / MTB / CX / Freestyle competitions nationwide. You can’t compete in Road or Track events on this licence. Note for Concession, Under 13 and 7 and Under memberships, we recommend an All-Discipline licence for the same cost without limiting disciplines.</p>

				<table class="table table-striped memtable">
					<tr>
						<td>Adult (Ages 19 - 64)</td>
						<td class="price">$244</td>
					</tr>
					<tr>
						<td>Concession (U19, 65+, Para)</td>
						<td class="price">$175</td>
					</tr>
					<tr>
						<td>Under 13 (Ages 8 - 12)</td>
						<td class="price">$109</td>
					</tr>
					<tr>
						<td>7 and under (Ages 2 - 7)</td>
						<td class="price">$81</td>
					</tr>
				</table>

				<span><a href="https://auscycling.org.au/membership/lifestyle" target="new"> Lifestyle:</a></span>
				<p>A Social, Leisure or commuting membership suitable for any bike. This licence can be used at MCC training rides, coaching clinics, Time Trials and any other non-racing event. This membership is for non-racing Coaches.</p>

				<table class="table table-striped memtable">
					<tr>
						<td>Adult (Ages 19 - 64)</td>
						<td class="price">$134</td>
					</tr>
					<tr>
						<td>Concession (U19, 65+, Para)</td>
						<td class="price">$81</td>
					</tr>
					<tr>
						<td>Under 13 (Ages 8 - 12)</td>
						<td class="price">$82</td>
					</tr>
					<tr>
						<td>7 and under (Ages 2 - 7)</td>
						<td class="price">$81</td>
					</tr>
				</table>
			</div>
		</div>
    </div>

    <div class="container">
		<div class="row">
			<div class="col-sm-12">
				<span><a href="https://auscycling.org.au/membership/non-riding" target="new">Non Riding: </a></span>
				<p>This membership is for volunteers and supporters who want to be part of the club but do not ride – a Club Officer, Accredited Official, Derny Rider, Moto Pacer. This membership with club fee is only $25 per year. You cannot belong to multiple clubs with a non-riding membership.</p>

				<span><a href="">Family Discounts</a> </span>
				<p>Family Memberships – discounts are available for members residing at the same address with a Lifestyle or Racing membership. Two members get a 10% discount, three or more a 15% discount. Further details are available here: <a href="https://auscycling.org.au/membership/other/family-membership">https://auscycling.org.au/membership/other/family-membership</a> (note club fee will be applied after the discount is calculated).</p>

				<span>Multiple Club Memberships </span>
				<p>AusCycling allows Race and Lifestyle members to join as many clubs as they want. You can join MCC with a “Club Add-On” for only $25 and still remain with your current club.</p>
				<p>You will need to choose which club will be your Primary Club on your membership profile for voting purposes under the AusCycling Constitution. Generally, your Primary Club will be the first club that you joined. To update your Primary Club raise a ticket at: <a href="https://desk.zoho.com/portal/auscycling/en/newticket">https://desk.zoho.com/portal/auscycling/en/newticket</a><br><br>

				Join MCC now at: <a href="https://auscycling.org.au/membership/other/club-add">https://auscycling.org.au/membership/other/club-add</a></p>

				<span>Transferring to MCC</span>
				<p>Transferring with an existing membership is as easy as contacting Memberships at AusCycling. We would prefer you do it before renewing so that club fees are appropriately allocated, but this can be done at any time. Contact the Membership Services team by raising a ticket: <a href="https://desk.zoho.com/portal/auscycling/en/newticket">https://desk.zoho.com/portal/auscycling/en/newticket</a></p>
	</div>
</div>
</div>
</template>
<script>
import UserService from "../services/user.service";
export default {
  name: "MemberShip",
  data() {
    return {
      content: "",
    };
  },
  mounted() {
    UserService.getPublicContent().then(
      (response) => {
        this.content = response.data;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
};
</script>

<style scoped>

h4 {
	color: #2d96ff;
	font-size: 1.5rem;
	font-weight: bold;
	font-style: italic;
	margin-bottom: 0px;
	padding-top: 50px;
	padding-bottom: 10px;
}

p {
	padding-top: 5px;
	padding-bottom: 5px;
	color: #777;
	line-height: 1.6rem;
	letter-spacing: 0.2rem;
}

.banner {
  width: 100%;
}
.banner img {
  width: 100%;
}

.memleft img {
	width: 80%;
	padding-top: 60px;
	padding-bottom: 30px;
	text-align: center;
}

.memright span {
	color:#2d96ff;
	font-weight: bold;
}

.memtable {
	width: 85%;
}
.memtable td{
	font-size: 0.9rem;
}
.memtable td.price {
	text-align: right;
}

span {
	color:#2d96ff;
	font-weight: bold;
}

</style>

