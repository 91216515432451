<template>
  <div class="container-fluid vh-100" style="margin-top:100px">
            <div class="" style="margin-top:50px">
                <div class="rounded d-flex justify-content-center">
                    <div class="col-md-6 col-sm-12 shadow-lg p-5 bg-light">
                        <div class="text-center">
                            <h3 class="text-primary">Create Account</h3>
                        </div>
                        <div class="p-4">
      <Form @submit="handleRegister" :validation-schema="schema">
        <div v-if="!successful">
          <div class="form-group">
            <label for="username">Membership Number</label>
            <Field name="username" type="text" class="form-control" />
            <ErrorMessage name="username" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="firstname">First Name</label>
            <Field name="firstname" type="text" class="form-control" />
            <ErrorMessage name="firstname" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="lastname">Last Name</label>
            <Field name="lastname" type="text" class="form-control" />
            <ErrorMessage name="lastname" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <Field name="email" type="email" class="form-control" />
            <ErrorMessage name="email" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <Field name="password" type="password" class="form-control" />
            <ErrorMessage name="password" class="error-feedback" />
          </div>
          <!--
          <div class="form-group">
            <label for="roles">Role</label>
            <Field name="roles" type="text" class="form-control" />
            <ErrorMessage name="roles" class="error-feedback" />
          </div>
          -->
          <div class="form-group">
            <label for="roles">Role(s)</label>
            <div class="row">
              <div class="col-md-4 check">
                <span><label>member</label></span><span><Field name="roles" type="checkbox" id="member" value="member" /></span>
              </div>
              <div class="col-md-4 check">
                <span><label>committee</label></span><span><Field name="roles" type="checkbox" id="committee" value="committee" /></span>
              </div>
              <div class="col-md-4 check">
                <span><label>admin</label></span><span><Field name="roles" type="checkbox" id="admin" value="admin" /></span>
              </div>
              <ErrorMessage name="roles" class="error-feedback" />
            </div>
          </div>
          <div class="form-group">
            <button class="btn btn-primary btn-block submit-btn" :disabled="loading">
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              Sign Up
            </button>
          </div>
        </div>
      </Form>
       <div
        v-if="message"
        class="alert"
        :class="successful ? 'alert-success' : 'alert-danger'">
        {{ message }}
      </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
export default {
  name: "UserRegister",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      firstname: yup
        .string()
        .required("First Name is required!"),
      lastname: yup
        .string()
        .required("Last Name is required!"),
      username: yup
        .string()
        .required("Username is required!")
        .min(7, "Must be 7 characters!")
        .max(7, "Must be 7 characters!"),
      email: yup
        .string()
        .required("Email is required!")
        .email("Email is invalid!")
        .max(50, "Must be maximum 50 characters!"),
      password: yup
        .string()
        .required("Password is required!")
        .min(6, "Must be at least 6 characters!")
        .max(40, "Must be maximum 40 characters!"),
      roles: yup
        .array()
        .required("Role is required!")
    });
    return {
      successful: false,
      loading: false,
      message: "",
      schema,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push("/profile");
    }
  },
  methods: {
    handleRegister(user) {
      this.message = "";
      this.successful = false;
      this.loading = true;
      this.$store.dispatch("auth/register", user).then(
        (data) => {
          this.message = data.message;
          this.successful = true;
          this.loading = false;
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.successful = false;
          this.loading = false;
        }
      );
    },
  },
};
</script>
<style scoped>

label {
  font-weight: bold;
	color: #555;
}

.check span {
  display:inline-block;
}
.check span label {
  margin-right: 10px;
  font-weight: 100;
}

.submit-btn {
  margin-top: 30px;
  float: right;
}

.alert, .alert-danger {
  margin-top: 100px;
}

</style>

